export const calculateAge = (birthDate: any) => {
  if (!birthDate) return 0;

  // Verifique se birthDate é uma string
  if (typeof birthDate !== 'string') {
    birthDate = birthDate instanceof Date ? birthDate.toLocaleDateString('pt-BR') : '';
  }

  // Converte a string 'DD/MM/YYYY' para 'YYYY-MM-DD'
  const [day, month, year] = birthDate.split('/');
  const formattedDate = `${year}-${month}-${day}`;

  const dtNascimento = new Date(formattedDate);

  // Verificação para evitar data inválida
  if (isNaN(dtNascimento.getTime())) {
    console.error("Data de nascimento inválida:", birthDate);
    return 0;
  }

  const today = new Date();
  let age = today.getFullYear() - dtNascimento.getFullYear();
  const monthDiff = today.getMonth() - dtNascimento.getMonth();
  const dayDiff = today.getDate() - dtNascimento.getDate();

  // Ajuste para calcular a idade corretamente considerando o mês e o dia
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};




export const verificaIdentidade = (nacionalidade: string | null) => {
  if (nacionalidade === null || (nacionalidade && nacionalidade.toLowerCase() !== 'brasileira')) {
    return false;
  }
  return true;
}

export const verificaEleitor = (dtNascimento: string | null, nacionalidade: string | null) => {
  if ((calculateAge(dtNascimento) < 18 || calculateAge(dtNascimento) > 70) || (nacionalidade === null || (nacionalidade && nacionalidade.toLowerCase() !== 'brasileira'))) {
    return false;
  }
  return true;
}

export const verificaReservista = (sexoColaborador: string | null, dtNascimento: string | null, nacionalidade: string | null) => {
  if ((calculateAge(dtNascimento) < 18 || calculateAge(dtNascimento) > 70) || (nacionalidade === null || (nacionalidade && nacionalidade.toLowerCase() !== 'brasileira'))) {
    return false;
  }
  if ((sexoColaborador !== 'M')) {
    return false;
  }
  return true;
}